<template>
    <div class="col-12" v-if="prodEnvironment">
        <h1 class="mt-5">FoxPay <small>Not Authorised</small></h1>
    </div>
    <div class="col-12" v-else>
        <h1 class="mt-5">FoxPay</h1>

        <ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#new" role="tab" aria-selected="true">New</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#deposit" role="tab" aria-selected="true">Deposit</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#withdrawal" role="tab" aria-selected="false">Withdrawal</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#others" role="tab" aria-selected="false">Others</a>
            </li>
        </ul>

        <div class="tab-content">
            <!--NEW-->
            <div class="tab-pane fade show active" id="new" role="tabpanel">
                <div class="table-responsive mt-4">
                    <h3>NEW <small>NEW Components after Guard/Token security was implemented</small></h3>
                    <table class="table table-striped mt-4">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Route</th>
                                <th scope="col">Properties</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <router-link to="/guard/?token=12345&language=fr">Guard</router-link>
                                </th>
                                <td>/guard/</td>
                                <td>?token=12345<br/>
                                    &language=fr
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/crypto">CoinsPaid Deposit</router-link>
                                </th>
                                <td>/deposit/crypto</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/withdraw/crypto">CoinsPaid Withdraw</router-link>
                                </th>
                                <td>/withdraw/crypto</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/paymentiq">PaymentIq Deposit</router-link>
                                </th>
                                <td>/deposit/paymentiq</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/withdraw/paymentiq">PaymentIq Withdraw</router-link>
                                </th>
                                <td>/withdraw/paymentiq</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/creditCard">Credit Card Deposit</router-link>
                                </th>
                                <td>/deposit/creditCard/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/asturkey">ASTurkey Deposit</router-link>
                                </th>
                                <td>/deposit/asturkey/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/withdraw/asturkey">ASTurkey Withdraw</router-link>
                                </th>
                                <td>/withdraw/asturkey/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/luqapay">Luqapay Deposit</router-link>
                                </th>
                                <td>/deposit/luqapay/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/withdraw/luqapay">Luqapay Withdraw</router-link>
                                </th>
                                <td>/withdraw/luqapay/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/maldopay">Maldopay Deposit</router-link>
                                </th>
                                <td>/deposit/maldopay/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/withdraw/maldopay">Maldopay Withdraw</router-link>
                                </th>
                                <td>/withdraw/maldopay/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/volt">Volt Deposit</router-link>
                                </th>
                                <td>/deposit/volt/</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <router-link to="/deposit/mandato">Mandato Deposit</router-link>
                                </th>
                                <td>/deposit/mandato/</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--DEPOSIT-->
            <div class="tab-pane fade show active" id="deposit" role="tabpanel">
                <div class="table-responsive mt-4">
                    <h3>Deposit <small>Pages Info</small></h3>
                    <table class="table table-striped mt-4">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Route</th>
                            <th scope="col">Properties</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">
                                <router-link to="/deposit/creditCard">Credit Card</router-link>
                            </th>
                            <td>/deposit/creditCard/</td>
                            <td>NOT IMPLEMENTED</td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/paymentCenter/?transactionid=56393815-5f8a-41b7-a1d5-a78693d69e98&language=fr">
                                    Payment Center
                                </router-link>
                            </th>
                            <td>/deposit/paymentCenter/</td>
                            <td>?transactionid=56393815-5f8a-41b7-a1d5-a78693d69e98<br/>
                                &language=fr
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/ikajo/?transactionid=56393815-5f8a-41b7-a1d5-a78693d69e98&language=fr">
                                    Ikajo
                                </router-link>
                            </th>
                            <td>/deposit/ikajo/</td>
                            <td>?transactionid=56393815-5f8a-41b7-a1d5-a78693d69e98<br/>
                                &language=fr
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/commercegate/?transactionid=78098bcf-df83-4a38-aacc-fee5554642b6&language=fr">
                                    Commercegate
                                </router-link>
                            </th>
                            <td>/deposit/commercegate/</td>
                            <td>?transactionid=78098bcf-df83-4a38-aacc-fee5554642b6<br/>
                                &language=fr
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/crypto/?language=fr&customerId=1738966&cryptoCurrencyCode=BTC&processorName=COINSPAID&ipAddress=127.0.0.1">
                                    Crypto
                                </router-link>
                            </th>
                            <td>/deposit/crypto/</td>
                            <td>?language=fr<br/>
                                &customerId=1738966<br/>
                                &cryptoCurrencyCode=BTC<br/>
                                &processorName=COINSPAID<br/>
                                &ipAddress=127.0.0.1<br/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/neteller/?paymentOption=NETELLER&brandUrl=https://www.slothino.com&language=fr&customerId=1&ipAddress=127.0.0.1&playerCurrency=EUR&playerCurrencySymbol=€&amount=1000">
                                    Neteller
                                </router-link>
                            </th>
                            <td>/deposit/neteller/</td>
                            <td>?paymentOption=NETELLER<br/>
                                &language=fr<br/>
                                &brandUrl=https://www.slothino.com<br/>
                                &customerId=1<br/>
                                &ipAddress=127.0.0.1<br/>
                                &playerCurrency=EUR<br/>
                                &playerCurrencySymbol=€<br/>
                                &amount=1000<br/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/ecommpay/loader?transactionid=56393815-5f8a-41b7-a1d5-a78693d69e98&language=fr">
                                    Ecommpay Loader
                                </router-link>
                            </th>
                            <td>/deposit/ecommpay/loader/</td>
                            <td>?transactionid=56393815-5f8a-41b7-a1d5-a78693d69e98<br/>
                                &language=fr
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/luqapay/community?walletType=INSTANT_QR&brandUrl=https://www.slothino.com&language=fr&customerId=333&ipAddress=127.0.0.1&playerCurrency=TRY&playerCurrencySymbol=₺&amount=2000">
                                    LUQAPAY COMMUNITY
                                </router-link>
                            </th>
                            <td>/deposit/luqapay/community</td>
                            <td>?walletType=INSTANT_QR<br/>
                                &language=fr<br/>
                                &brandUrl=https://www.slothino.com<br/>
                                &customerId=333<br/>
                                &ipAddress=127.0.0.1<br/>
                                &playerCurrency=TRY<br/>
                                &playerCurrencySymbol=₺<br/>
                                &amount=2000<br/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/paymentiq?walletType=DEFAULT&brandUrl=https://www.slothino.com&language=en&customerId=1&processorName=PAYMENTIQ&ipAddress=127.0.0.1&playerCurrency=EUR&playerCurrencySymbol=$&amount=9000">
                                    PaymentIQ
                                </router-link>
                            </th>
                            <td>/deposit/paymentiq</td>
                            <td>?walletType=DEFAULT<br/>
                                &language=en<br/>
                                &brandUrl=https://www.slothino.com<br/>
                                &customerId=1<br/>
                                &ipAddress=127.0.0.1<br/>
                                &playerCurrency=EUR<br/>
                                &playerCurrencySymbol=$<br/>
                                &amount=9000<br/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/deposit/?paymentOption=IDEAL&brandUrl=https://www.slothino.com&language=fr&customerId=510384&ipAddress=127.0.0.1&playerCurrency=EUR&playerCurrencySymbol=€&amount=1000">
                                    APCO
                                </router-link>
                            </th>
                            <td>/deposit/</td>
                            <td>?paymentOption=IDEAL<br/>
                                &language=fr<br/>
                                &brandUrl=https://www.slothino.com<br/>
                                &customerId=1<br/>
                                &ipAddress=127.0.0.1<br/>
                                &playerCurrency=EUR<br/>
                                &playerCurrencySymbol=€<br/>
                                &amount=1000<br/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!--WITHDRAWAL-->
            <div class="tab-pane fade" id="withdrawal" role="tabpanel">
                <div class="table-responsive mt-4">
                    <h3>Withdrawal <small>Pages Info</small></h3>
                    <table class="table table-striped mt-4">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Route</th>
                            <th scope="col">Properties</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/withdraw/?language=en&maxWithdrawal=1000&playerCurrency=TRY&playerCurrencySymbol=TRY&customerId=333&processorName=LUQAPAY&processorType=EWALLET&walletType=BANKTRANSFER&ipAddress=127.0.0.1&token=7358284500c4a1327746ba3891b121313483b24d767ddd27f64cae81bb510dd4">
                                    Withdraw
                                </router-link>
                            </th>
                            <td>/withdraw/</td>
                            <td>?language=en<br/>
                                &maxWithdrawal=1000<br/>
                                &playerCurrency=TRY<br/>
                                &playerCurrencySymbol=TRY<br/>
                                &customerId=333<br/>
                                &processorName=LUQAPAY<br/>
                                &processorType=EWALLET<br/>
                                &walletType=BANKTRANSFER<br/>
                                &ipAddress=127.0.0.1<br/>
                                &token=7358284500c4a1327746ba3891b121313483b24d767ddd27f64cae81bb510dd4<br/>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/withdraw/paymentiq?walletType=NETELLER&brandUrl=https://www.slothino.com&language=en&customerId=503761&processorName=PAYMENTIQ&ipAddress=127.0.0.1&playerCurrency=EUR&playerCurrencySymbol=$&amount=9000">
                                    PaymentIQ
                                </router-link>
                            </th>
                            <td>/withdraw/paymentiq</td>
                            <td>?walletType=DEFAULT<br/>
                                &language=en<br/>
                                &brandUrl=https://www.slothino.com<br/>
                                &customerId=1<br/>
                                &ipAddress=127.0.0.1<br/>
                                &playerCurrency=EUR<br/>
                                &playerCurrencySymbol=$<br/>
                                &amount=9000<br/>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                                <router-link
                                    to="withdraw/neteller/?language=en&maxWithdrawal=1000&playerCurrency=EUR&playerCurrencySymbol=TRY&customerId=333&ipAddress=127.0.0.1&token=7358284500c4a1327746ba3891b121313483b24d767ddd27f64cae81bb510dd4">
                                    Neteller Withdraw
                                </router-link>
                            </th>
                            <td>/withdraw/neteller</td>
                            <td>?language=en<br/>
                                &maxWithdrawal=1000<br/>
                                &playerCurrency=EUR<br/>
                                &playerCurrencySymbol=€<br/>
                                &customerId=333<br/>
                                &ipAddress=127.0.0.1<br/>
                                &token=7358284500c4a1327746ba3891b121313483b24d767ddd27f64cae81bb510dd4<br/>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/withdraw/crypto/?language=fr&maxWithdrawal=1000&playerCurrency=EUR&playerCurrencySymbol=€&customerId=1738966&cryptoCurrencyCode=BTC&processorName=COINSPAID&ipAddress=127.0.0.1">
                                    Crypto
                                </router-link>
                            </th>
                            <td>/withdraw/crypto/</td>
                            <td>?language=fr<br/>
                                &customerId=1738966<br/>
                                &maxWithdrawal=1000<br/>
                                &playerCurrency=EUR<br/>
                                &playerCurrencySymbol=€<br/>
                                &cryptoCurrencyCode=BTC<br/>
                                &processorName=COINSPAID<br/>
                                &ipAddress=127.0.0.1<br/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!--OTHERS-->
            <div class="tab-pane fade" id="others" role="tabpanel">
                <div class="table-responsive mt-4">
                    <h3>Other <small>Pages Info</small></h3>
                    <table class="table table-striped mt-4">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Route</th>
                            <th scope="col">Properties</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">
                                <router-link
                                    to="/receipt/?transactionid=dadddbf5-8291-4881-9360-e4797a57bb3b&language=fr">
                                    Receipt
                                </router-link>
                            </th>
                            <td>/receipt/</td>
                            <td>?language=fr<br/>
                                &transactionid=dadddbf5-8291-4881-9360-e4797a57bb3b<br/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                <router-link to="/receiptWithdraw/?transactionid=9c4f7e1a-4e5a-4e5a-8bd6-c50feb1123d0">
                                    Receipt Withdraw
                                </router-link>
                            </th>
                            <td>/receiptWithdraw/</td>
                            <td>?transactionid=9c4f7e1a-4e5a-4e5a-8bd6-c50feb1123d0</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'home',
    data: function () {
        return {
            prodEnvironment: process.env.NODE_ENV === 'production'
        }
    },
}
</script>
